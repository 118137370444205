import React from "react"
import { IconType } from "./Icons.type"

export const MastercutLogo = ({ width, height, background, className }: IconType): JSX.Element => {
  return (
    <svg
      className={className}
      width={width || "168"}
      height={height || "92"}
      viewBox="0 0 168 92"
      fill={background || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M84.0314 26.7233C87.5569 31.557 88.3193 35.092 89.5134 34.7545C90.4491 34.4891 89.9994 33.6955 92.4677 33.1338C94.8378 32.5953 97.2596 34.5303 97.7611 36.8621C98.4305 39.1501 97.3449 42.0256 94.9154 42.7341C92.5116 43.5999 89.6453 42.1931 88.7897 39.8123C87.5517 37.0915 86.9159 33.4971 82.9097 30.413L79.8339 28.0451L69.1929 13.1113C68.004 11.1815 67.288 8.61264 68.8311 6.16748L84.0288 26.7233H84.0314ZM95.4452 35.7388C93.3956 33.7058 89.7719 34.9452 89.7771 37.6583C89.6323 40.155 92.6848 42.4275 94.9515 41.0027C96.8616 40.003 96.8487 37.1301 95.4452 35.7388Z"
          fill="#CD8C1E"
        />
        <path
          d="M78.5948 26.5919C75.0771 31.4333 74.3224 34.9684 73.1282 34.6334C72.1926 34.3706 72.6397 33.577 70.1714 33.0205C67.7987 32.4871 65.3821 34.4273 64.8858 36.7617C64.219 39.0496 65.3123 41.9225 67.7444 42.6259C70.1507 43.4865 73.0119 42.0745 73.8623 39.6912C75.0952 36.9678 75.7232 33.3709 79.7217 30.279L82.7923 27.9034L93.3997 12.9464C94.586 11.014 95.2942 8.44259 93.746 6L78.5948 26.5919ZM67.1991 35.6305C69.2435 33.5925 72.8724 34.8241 72.8698 37.5372C73.0223 40.0339 69.975 42.3142 67.7031 40.8945C65.7904 39.8999 65.7982 37.0245 67.1991 35.6305Z"
          fill="#CD8C1E"
        />
        <path
          d="M6 67.1998H7.74464V56.6024L12.3479 63.523H13.7746L18.3753 56.6204V67.1998H20.1225V53.3585H18.4968L13.0794 61.5648L7.62574 53.3585H6V67.1998Z"
          fill="white"
        />
        <path
          d="M26.0918 67.1998H27.9579L29.227 64.0564H35.991L37.2988 67.1998H39.1443L33.4322 53.3585H31.8065L26.0918 67.1998ZM29.9016 62.4357L32.618 55.7907L35.3371 62.4357H29.9016Z"
          fill="white"
        />
        <path
          d="M49.7374 67.4575C52.7718 67.4575 54.7362 65.9734 54.7362 63.5617C54.7362 61.5236 53.3663 60.2998 49.8176 59.4289C47.1192 58.777 46.2275 58.0659 46.2275 56.9167C46.2275 55.5924 47.4164 54.7627 49.3989 54.7627C51.4433 54.7627 52.7124 55.7908 52.7511 57.4527H54.4777C54.457 54.7627 52.5134 53.1008 49.3989 53.1008C46.3645 53.1008 44.4415 54.5849 44.4415 56.9167C44.4415 59.0527 45.969 60.2405 49.3808 61.0495C51.8801 61.6447 52.9295 62.3353 52.9295 63.621C52.9295 64.984 51.7018 65.7956 49.7374 65.7956C47.5741 65.7956 46.0672 64.7289 46.0285 62.9485H44.2812C44.3019 65.6564 46.4239 67.4575 49.7374 67.4575Z"
          fill="white"
        />
        <path d="M64.9124 67.1998H66.6571V55.0203H71.3379V53.3585H60.2109V55.0203H64.9124V67.1998Z" fill="white" />
        <path
          d="M77.666 67.1998H86.3143V65.5791H79.4107V60.9516H85.5983V59.349H79.4107V54.9791H86.0946V53.3585H77.666V67.1998Z"
          fill="white"
        />
        <path
          d="M93.2383 67.1998H94.9829V62.2373H98.038L101.429 67.1998H103.533L100.041 62.0595C102.065 61.5648 103.313 60.0421 103.313 57.8082C103.313 55.059 101.411 53.3585 98.5342 53.3585H93.2383V67.1998ZM94.9829 60.5754V55.0203H98.3352C100.359 55.0203 101.53 56.0484 101.53 57.8082C101.53 59.568 100.359 60.5754 98.3352 60.5754H94.9829Z"
          fill="white"
        />
        <path
          d="M116.505 67.4575C119.875 67.4575 122.612 65.4194 123.447 62.3353H121.602C120.888 64.412 118.924 65.7157 116.564 65.7157C113.471 65.7157 111.268 63.4818 111.268 60.2791C111.268 57.0765 113.471 54.8426 116.564 54.8426C118.865 54.8426 120.749 56.0665 121.462 58.0659H123.328C122.416 55.0204 119.777 53.1008 116.505 53.1008C112.419 53.1008 109.482 56.1077 109.482 60.2791C109.482 64.4506 112.419 67.4575 116.505 67.4575Z"
          fill="white"
        />
        <path
          d="M135.346 67.4368C138.698 67.4368 140.722 65.2416 140.722 61.5648V53.3585H138.977V61.4463C138.977 64.2728 137.726 65.7363 135.346 65.7363C132.986 65.7363 131.717 64.2728 131.717 61.4463V53.3585H129.99V61.5648C129.99 65.2416 131.993 67.4368 135.346 67.4368Z"
          fill="white"
        />
        <path d="M151.575 67.1998H153.319V55.0203H158V53.3585H146.873V55.0203H151.575V67.1998Z" fill="white" />
        <path
          d="M39.9238 42.6112H44.7597V41.7995H41.3531L43.3588 39.9805C44.2221 39.1869 44.6201 38.4165 44.6201 37.6332C44.6201 36.4171 43.6457 35.5565 42.3172 35.5565C40.9577 35.5565 40.0453 36.4583 40.004 37.8419H40.8181C40.8491 36.8835 41.4436 36.3192 42.3276 36.3192C43.1805 36.3192 43.775 36.8422 43.775 37.6332C43.775 38.2078 43.4674 38.7128 42.8031 39.326L39.9238 41.9593V42.6112Z"
          fill="white"
        />
        <path
          d="M49.0439 42.74C50.7808 42.74 51.8431 41.3641 51.8431 39.1483C51.8431 36.9324 50.7808 35.5565 49.0439 35.5565C47.3173 35.5565 46.2344 36.9324 46.2344 39.1483C46.2344 41.3641 47.3173 42.74 49.0439 42.74ZM47.0976 39.1483C47.0976 37.4477 47.842 36.3965 49.0439 36.3965C50.2458 36.3965 50.9798 37.4477 50.9798 39.1483C50.9798 40.8488 50.2458 41.9 49.0439 41.9C47.842 41.9 47.0976 40.8488 47.0976 39.1483Z"
          fill="white"
        />
        <path
          d="M110.891 42.6112H115.727V41.7995H112.32L114.326 39.9805C115.189 39.1869 115.587 38.4165 115.587 37.6332C115.587 36.4171 114.613 35.5565 113.284 35.5565C111.924 35.5565 111.012 36.4583 110.971 37.8419H111.785C111.816 36.8835 112.41 36.3192 113.294 36.3192C114.147 36.3192 114.742 36.8422 114.742 37.6332C114.742 38.2078 114.434 38.7128 113.77 39.326L110.891 41.9593V42.6112Z"
          fill="white"
        />
        <path
          d="M117.252 42.6112H122.088V41.7995H118.681L120.687 39.9805C121.55 39.1869 121.948 38.4165 121.948 37.6332C121.948 36.4171 120.974 35.5565 119.645 35.5565C118.286 35.5565 117.373 36.4583 117.332 37.8419H118.146C118.177 36.8835 118.772 36.3192 119.656 36.3192C120.509 36.3192 121.103 36.8422 121.103 37.6332C121.103 38.2078 120.796 38.7128 120.131 39.326L117.252 41.9593V42.6112Z"
          fill="white"
        />
        <path
          d="M23.6875 81.8918H26.0576C27.2336 81.8918 27.9677 81.2682 27.9677 80.2608C27.9677 79.6038 27.642 79.1142 27.0424 78.872C27.518 78.6324 27.7997 78.1841 27.7997 77.6172C27.7997 76.6768 27.0656 76.0713 25.9491 76.0713H23.6875V81.8918ZM24.4215 81.209V79.2559H26.0318C26.7917 79.2559 27.2155 79.6141 27.2155 80.2273C27.2155 80.8534 26.7917 81.209 26.0395 81.209H24.4215ZM24.4215 78.5912V76.7515H25.931C26.6418 76.7515 27.0501 77.0839 27.0501 77.6765C27.0501 78.2588 26.6495 78.5912 25.931 78.5912H24.4215Z"
          fill="#CD8C1E"
        />
        <path
          d="M30.0898 81.8918H30.873L31.408 80.57H34.2511L34.8017 81.8918H35.5796L33.1759 76.0713H32.491L30.0898 81.8918ZM31.6923 79.8872L32.8347 77.0942L33.9772 79.8872H31.6923Z"
          fill="#CD8C1E"
        />
        <path
          d="M38.0859 81.8918H38.82V79.8047H40.1045L41.5313 81.8918H42.4152L40.9471 79.73C41.7975 79.5213 42.3248 78.8823 42.3248 77.9419C42.3248 76.785 41.5235 76.0713 40.3139 76.0713H38.0859V81.8918ZM38.82 79.1065V76.7695H40.2312C41.0815 76.7695 41.5726 77.2024 41.5726 77.9419C41.5726 78.6814 41.0815 79.1065 40.2312 79.1065H38.82Z"
          fill="#CD8C1E"
        />
        <path
          d="M45.2812 81.8918H47.6514C48.8274 81.8918 49.5614 81.2682 49.5614 80.2608C49.5614 79.6038 49.2358 79.1142 48.6361 78.872C49.1117 78.6324 49.3934 78.1841 49.3934 77.6172C49.3934 76.6768 48.6594 76.0713 47.5428 76.0713H45.2812V81.8918ZM46.0153 81.209V79.2559H47.6255C48.3854 79.2559 48.8093 79.6141 48.8093 80.2273C48.8093 80.8534 48.3854 81.209 47.6333 81.209H46.0153ZM46.0153 78.5912V76.7515H47.5247C48.2355 76.7515 48.6439 77.0839 48.6439 77.6765C48.6439 78.2588 48.2433 78.5912 47.5247 78.5912H46.0153Z"
          fill="#CD8C1E"
        />
        <path
          d="M52.3926 81.8918H56.0292V81.209H53.1266V79.2637H55.7294V78.5912H53.1266V76.7515H55.9387V76.0713H52.3926V81.8918Z"
          fill="#CD8C1E"
        />
        <path
          d="M58.9355 81.8918H59.6696V79.8047H60.9542L62.3809 81.8918H63.2648L61.7968 79.73C62.6471 79.5213 63.1744 78.8823 63.1744 77.9419C63.1744 76.785 62.3731 76.0713 61.1635 76.0713H58.9355V81.8918ZM59.6696 79.1065V76.7695H61.0808C61.9312 76.7695 62.4222 77.2024 62.4222 77.9419C62.4222 78.6814 61.9312 79.1065 61.0808 79.1065H59.6696Z"
          fill="#CD8C1E"
        />
        <path
          d="M68.0797 82C69.3565 82 70.1836 81.3765 70.1836 80.3613C70.1836 79.5059 69.6072 78.9906 68.1133 78.6247C66.9786 78.349 66.6039 78.0502 66.6039 77.5683C66.6039 77.0092 67.1053 76.6614 67.9375 76.6614C68.7982 76.6614 69.3307 77.0942 69.3488 77.7925H70.075C70.0647 76.6614 69.248 75.9631 67.9375 75.9631C66.6633 75.9631 65.8517 76.5867 65.8517 77.5683C65.8517 78.465 66.4953 78.9648 67.9298 79.3049C68.9817 79.5549 69.4237 79.846 69.4237 80.3871C69.4237 80.9617 68.9068 81.3018 68.0797 81.3018C67.1699 81.3018 66.5367 80.8535 66.5212 80.1037H65.7871C65.7949 81.2425 66.6866 82 68.0797 82Z"
          fill="#CD8C1E"
        />
        <path
          d="M76.8754 81.8918H77.6094V76.0713H76.8754V78.5731H73.779V76.0713H73.0449V81.8918H73.779V79.2714H76.8754V81.8918Z"
          fill="#CD8C1E"
        />
        <path
          d="M83.4785 82C85.2541 82 86.5051 80.753 86.5051 78.9803C86.5051 77.2102 85.2541 75.9631 83.4785 75.9631C81.7002 75.9631 80.457 77.2102 80.457 78.9803C80.457 80.753 81.7002 82 83.4785 82ZM81.2092 78.9803C81.2092 77.625 82.1345 76.6949 83.4785 76.6949C84.8199 76.6949 85.7452 77.625 85.7452 78.9803C85.7452 80.3356 84.8199 81.2683 83.4785 81.2683C82.1345 81.2683 81.2092 80.3356 81.2092 78.9803Z"
          fill="#CD8C1E"
        />
        <path
          d="M89.3457 81.8918H90.0797V79.9799H91.323C92.6411 79.9799 93.4915 79.2224 93.4915 78.0243C93.4915 76.8365 92.6411 76.0713 91.323 76.0713H89.3457V81.8918ZM90.0797 79.2972V76.7695H91.3075C92.2483 76.7695 92.7419 77.2024 92.7419 78.0321C92.7419 78.8643 92.2483 79.2972 91.3075 79.2972H90.0797Z"
          fill="#CD8C1E"
        />
        <path
          d="M101.362 82C102.088 82 102.68 81.7991 103.132 81.4255L103.59 81.8918H104.533L103.574 80.9179C103.89 80.4438 104.058 79.8203 104.073 79.0731H103.373C103.365 79.5961 103.257 80.0444 103.065 80.3948L101.755 79.0731C102.747 78.6891 103.249 78.1249 103.249 77.4009C103.249 76.5454 102.548 75.9631 101.53 75.9631C100.519 75.9631 99.837 76.5197 99.837 77.3339C99.837 77.8492 100.103 78.3233 100.563 78.8231C99.7543 79.207 99.377 79.7223 99.377 80.4283C99.377 81.4177 100.121 82 101.362 82ZM100.077 80.4129C100.077 79.9465 100.388 79.5884 101.047 79.323L102.623 80.9282C102.305 81.1858 101.881 81.3276 101.372 81.3276C100.571 81.3276 100.077 80.9849 100.077 80.4129ZM100.519 77.3261C100.519 76.8855 100.912 76.6021 101.522 76.6021C102.155 76.6021 102.564 76.9113 102.564 77.4009C102.564 77.8672 102.174 78.2151 101.253 78.55C100.721 77.9832 100.519 77.6431 100.519 77.3261Z"
          fill="#CD8C1E"
        />
        <path
          d="M110.789 81.8918H111.523V77.4343L113.459 80.3458H114.059L115.995 77.442V81.8918H116.729V76.0713H116.046L113.767 79.5213L111.474 76.0713H110.789V81.8918Z"
          fill="#CD8C1E"
        />
        <path
          d="M122.602 82C124.377 82 125.628 80.753 125.628 78.9803C125.628 77.2102 124.377 75.9631 122.602 75.9631C120.823 75.9631 119.58 77.2102 119.58 78.9803C119.58 80.753 120.823 82 122.602 82ZM120.332 78.9803C120.332 77.625 121.258 76.6949 122.602 76.6949C123.943 76.6949 124.868 77.625 124.868 78.9803C124.868 80.3356 123.943 81.2683 122.602 81.2683C121.258 81.2683 120.332 80.3356 120.332 78.9803Z"
          fill="#CD8C1E"
        />
        <path
          d="M128.469 81.8918H129.203V79.8047H130.487L131.914 81.8918H132.798L131.33 79.73C132.18 79.5213 132.708 78.8823 132.708 77.9419C132.708 76.785 131.906 76.0713 130.697 76.0713H128.469V81.8918ZM129.203 79.1065V76.7695H130.614C131.464 76.7695 131.955 77.2024 131.955 77.9419C131.955 78.6814 131.464 79.1065 130.614 79.1065H129.203Z"
          fill="#CD8C1E"
        />
        <path
          d="M135.664 81.8918H139.301V81.209H136.398V79.2637H139.001V78.5912H136.398V76.7515H139.21V76.0713H135.664V81.8918Z"
          fill="#CD8C1E"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_53_2127"
          x="0"
          y="0"
          width="168"
          height="92.0001"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_53_2127" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_53_2127" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
