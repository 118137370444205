import React from "react"
import { IconType } from "./Icons.type"
import { useWindowSize } from "../../helpers/customHooks"

export const CalendarIcon = ({ width, height, background, fill, className }: IconType): JSX.Element => {
  const { isMobile } = useWindowSize()
  return (
    <svg
      className={className}
      width={width || isMobile ? "36" : "48"}
      height={height || isMobile ? "36" : "48"}
      viewBox="0 0 48 48"
      fill={background || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill={fill || "#DCB675"} />
      <path
        d="M17.3333 24H20V26.8H17.3333V24ZM36 15.6V35.2C36 35.9426 35.719 36.6548 35.219 37.1799C34.7189 37.705 34.0406 38 33.3333 38H14.6667C13.1867 38 12 36.74 12 35.2V15.6C12 14.8574 12.281 14.1452 12.781 13.6201C13.2811 13.095 13.9594 12.8 14.6667 12.8H16V10H18.6667V12.8H29.3333V10H32V12.8H33.3333C34.0406 12.8 34.7189 13.095 35.219 13.6201C35.719 14.1452 36 14.8574 36 15.6ZM14.6667 18.4H33.3333V15.6H14.6667V18.4ZM33.3333 35.2V21.2H14.6667V35.2H33.3333ZM28 26.8V24H30.6667V26.8H28ZM22.6667 26.8V24H25.3333V26.8H22.6667ZM17.3333 29.6H20V32.4H17.3333V29.6ZM28 32.4V29.6H30.6667V32.4H28ZM22.6667 32.4V29.6H25.3333V32.4H22.6667Z"
        fill="#BE7800"
      />
    </svg>
  )
}
