import React from "react"

export const LocationMap = (): JSX.Element => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d683.2432852979218!2d23.570316893516107!3d46.76542587452627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47490f93e482983d%3A0xb402a72391cc0c4d!2sMasterCut!5e0!3m2!1sen!2sro!4v1647253723897!5m2!1sen!2sro"
      width="100%"
      height="100%"
      style={{ border: 0 }}
      loading="lazy"
    />
  )
}
