import React from "react"

import { SectionContainer } from "../SectionContainer/SectionContainer"
import { SectionType } from "../../shared/types"

const SECTION_DESCRIPTION = `<strong>MasterCut e mai mult decât un barber shop</strong>. MasterCut e o experiență - o experiență premium. E un loc
            social, în care clienții noștri se vor delecta cu o varietate de servicii adresate special nevoilor lor, dar
            și cu un pahar din băutura lor preferată sau de o cafea perfect echilibrată.
            <strong>MasterCut e un prilej de grijă de sine, de socializare și de networking.</strong>`

export const AboutSection = ({ id }: SectionType): JSX.Element => {
  return (
    <SectionContainer
      id={id}
      backgroundColor={"light"}
      icon={{ icon: "group", text: "Despre MasterCut" }}
      description={SECTION_DESCRIPTION}
    />
  )
}
