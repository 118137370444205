import React from "react"
import { Grid } from "@mui/material"

import { DevNestLogo } from "../Icons/DevNestLogo"
import { commonStyles } from "../../typestyles/CommonStyles.module"

export const DevnestSection = (): JSX.Element => {
  return (
    <Grid container bgcolor={"#F5F5F5"} padding={"16px 24px"}>
      <Grid md={7} xs={12} marginX={"auto"} item className={commonStyles.displayFlexCenter}>
        <h1 className={commonStyles.title}>Powered by</h1>
        <DevNestLogo className={commonStyles.marginX24} />
      </Grid>
    </Grid>
  )
}
