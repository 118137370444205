import React from "react"
import { IconType } from "./Icons.type"
import { useWindowSize } from "../../helpers/customHooks"

export const InfoIcon = ({ width, height }: IconType): JSX.Element => {
  const { isMobile } = useWindowSize()
  return (
    <svg
      width={width || isMobile ? "36" : "48"}
      height={height || isMobile ? "36" : "48"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#DCB675" />
      <rect x="21" y="20" width="6" height="16" fill="#BE7800" />
      <rect x="21" y="12" width="6" height="6" fill="#BE7800" />
    </svg>
  )
}
