import React from "react"
import { IconType } from "./Icons.type"
import { useWindowSize } from "../../helpers/customHooks"

export const GroupIcon = ({ width, height }: IconType): JSX.Element => {
  const { isMobile } = useWindowSize()
  return (
    <svg
      width={width || isMobile ? "36" : "48"}
      height={height || isMobile ? "36" : "48"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#DCB675" />
      <path
        d="M29.8182 22.1429C32.2327 22.1429 34.1673 20.101 34.1673 17.5714C34.1673 15.0419 32.2327 13 29.8182 13C27.4036 13 25.4545 15.0419 25.4545 17.5714C25.4545 20.101 27.4036 22.1429 29.8182 22.1429ZM18.1818 22.1429C20.5964 22.1429 22.5309 20.101 22.5309 17.5714C22.5309 15.0419 20.5964 13 18.1818 13C15.7673 13 13.8182 15.0419 13.8182 17.5714C13.8182 20.101 15.7673 22.1429 18.1818 22.1429ZM18.1818 25.1905C14.7927 25.1905 8 26.9733 8 30.5238V34.3333H28.3636V30.5238C28.3636 26.9733 21.5709 25.1905 18.1818 25.1905ZM29.8182 25.1905C29.3964 25.1905 28.9164 25.221 28.4073 25.2667C30.0945 26.5467 31.2727 28.2686 31.2727 30.5238V34.3333H40V30.5238C40 26.9733 33.2073 25.1905 29.8182 25.1905Z"
        fill="#BE7800"
      />
    </svg>
  )
}
