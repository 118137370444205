import { useEffect, useState } from "react"

export interface WindowSize {
  width: number
  height: number
  isMobile: boolean
}

const LAYOUT_BREAK_POINT = 767

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    isMobile: false,
  })

  useEffect(() => {
    const handleResize = (): void => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < LAYOUT_BREAK_POINT,
      })
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return (): void => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}
