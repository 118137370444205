import { media, style } from "typestyle"
import heroImageV2 from "../../assets/images/Masked_hero_V2.png"
export const heroImage = style(
  {
    backgroundImage: `url("${heroImageV2}")`,
    height: "80vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  media({ maxWidth: 767 }, { height: "45vh" })
)

export const heroCta = style(
  {
    position: "absolute",
    bottom: "15%",
    display: "block",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  media({ maxWidth: 767 }, { display: "none" })
)
