import React from "react"
import { stylesheet } from "typestyle"
import Grid from "@mui/material/Grid"

import { SectionIcon } from "../SectionIcon/SectionIcon"
import { SectionContainer } from "../SectionContainer/SectionContainer"
import { SectionType } from "../../shared/types"
import { commonStyles } from "../../typestyles/CommonStyles.module"

export const ScheduleSection = ({ id }: SectionType): JSX.Element => {
  return (
    <SectionContainer id={id} padding={"24px"}>
      <Grid item sm={7} xs={6} md={7} className={commonStyles.displayFlexStart}>
        <SectionIcon icon={"calendar"} text={"Orar"} />
      </Grid>
      <Grid item sm={5} xs={6} md={5}>
        <div className={scheduleStyles.scheduleSection}>
          <div className={scheduleStyles.scheduleContainer}>
            <p className={scheduleStyles.days}>Luni-Vineri</p>
            <p className={commonStyles.marginNone}>09-19</p>
          </div>
          <div className={scheduleStyles.scheduleContainer}>
            <p className={scheduleStyles.days}>Sambata</p>
            <p className={commonStyles.marginNone}>09-15:30</p>
          </div>
        </div>
      </Grid>
    </SectionContainer>
  )
}

const scheduleStyles = stylesheet({
  scheduleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  },
  scheduleContainer: {
    margin: "0 5px",
    textAlign: "right",
  },
  days: {
    fontFamily: "ChivoBold, 'Open Sans', sans-serif",
    textTransform: "uppercase",
    fontSize: "12px",
    margin: "0",
  },
})
