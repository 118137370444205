import React from "react"
import { IconType } from "./Icons.type"

export const CloseIcon = ({ width, height, background, fill, className }: IconType): JSX.Element => {
  return (
    <svg
      className={className}
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill={background || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
        fill={fill || "white"}
      />
    </svg>
  )
}
