import React, { useState } from "react"

import { Grid } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { SectionContainer } from "../SectionContainer/SectionContainer"

import { servicesList } from "./PricesSection.constants"
import { SectionType } from "../../shared/types"

import { commonStyles } from "../../typestyles/CommonStyles.module"

const ServiceDescription = ({ description }: { description: string }): JSX.Element => {
  const [showDescription, setShowDescription] = useState(false)
  return (
    <Grid item sm={12} xs={12} md={7} marginBottom={"16px"} marginTop={showDescription ? "7px" : "0"}>
      <Grid
        container
        maxWidth={{ sm: "100%", md: `${showDescription ? "95%" : "90%"}` }}
        display={showDescription ? "block" : "inline-flex"}
      >
        <Grid
          item
          md={12}
          className={showDescription ? commonStyles.displayInline : commonStyles.cutText}
          maxWidth={{ md: `${showDescription ? "100%" : "82%"}` }}
          paddingTop={"1px"}
          display={{
            md: "inline",
            sm: `${showDescription ? "block" : "none"}`,
            xs: `${showDescription ? "block" : "none"}`,
          }}
        >
          <small className={commonStyles.serviceDescription}>{description}</small>
        </Grid>
        <span className={commonStyles.showMoreButton} onClick={() => setShowDescription(!showDescription)}>
          detalii
          <ExpandMore className={showDescription ? commonStyles.rotate180 : ""} />
        </span>
      </Grid>
    </Grid>
  )
}

export const PricesSection = ({ id }: SectionType): JSX.Element => {
  return (
    <SectionContainer
      id={id}
      backgroundColor={"light"}
      icon={{ icon: "wallet", text: "Prețuri" }}
      iconMargin={"0 0 24px"}
    >
      <Grid container>
        {servicesList.map((service) => (
          <>
            <Grid
              item
              key={`${service.title}-${service.price}`}
              sm={7}
              xs={7}
              md={7}
              marginBottom={service.description ? "0" : "16px"}
              paddingRight={"13px"}
            >
              <p className={commonStyles.serviceTitle}>{service.title}</p>
            </Grid>
            <Grid
              item
              key={`${service.price}-${service.title}`}
              sm={5}
              xs={5}
              md={5}
              marginBottom={service.description ? "0" : "16px"}
            >
              <div className={commonStyles.displayFlexBetween}>
                <p className={commonStyles.serviceTitle}>{service.time}</p>
                <p className={commonStyles.serviceTitle}>
                  <strong>{service.price}</strong>
                </p>
              </div>
            </Grid>

            {service.description && <ServiceDescription description={service.description} />}
          </>
        ))}
      </Grid>
    </SectionContainer>
  )
}
