import React, { useState } from "react"
import classNames from "classnames"
import { stylesheet } from "typestyle"

import { Box, Grid, Dialog, IconButton } from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLefttIcon from "@mui/icons-material/ChevronLeft"
import { SectionContainer } from "../SectionContainer/SectionContainer"
import { IMAGES_LIST } from "./ImagesSection.constants"
import { SectionType } from "../../shared/types"

import { commonStyles } from "../../typestyles/CommonStyles.module"

const ctaButtonClasses = classNames(commonStyles.btn, commonStyles.btnSecondary)

const MIN_IMAGES_NUMBER = 6

export const ImagesSection = ({ id }: SectionType): JSX.Element => {
  const [showImagesCount, setShowImagesCount] = useState<number>(MIN_IMAGES_NUMBER)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1)

  const onShowMore = () => {
    setShowImagesCount((prev) => (IMAGES_LIST.length > showImagesCount ? prev + MIN_IMAGES_NUMBER : MIN_IMAGES_NUMBER))
  }
  const handleImageClick = (index: number): void => {
    setSelectedImageIndex(index)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
    setSelectedImageIndex(-1)
  }

  const nextImage = () => {
    const nextIndex = selectedImageIndex + 1
    setSelectedImageIndex(nextIndex < IMAGES_LIST.length ? nextIndex : 0)
  }

  const prevImage = () => {
    const nextIndex = selectedImageIndex - 1
    setSelectedImageIndex(nextIndex >= 0 ? nextIndex : IMAGES_LIST.length - 1)
  }

  return (
    <SectionContainer id={id} icon={{ icon: "services", text: "Galerie imagini" }}>
      <Grid container>
        {IMAGES_LIST.map((item, index) => (
          <Grid
            key={index.toString()}
            item
            xl={4}
            md={4}
            sm={4}
            xs={6}
            padding={1}
            display={index + 1 <= showImagesCount ? "block" : "none"}
          >
            <Box
              component={"img"}
              src={`${item.img}`}
              srcSet={`${item.img}`}
              alt={item.title}
              loading="lazy"
              style={{ width: "100%", height: "auto", cursor: "pointer" }}
              onClick={(): void => handleImageClick(index)}
            />
          </Grid>
        ))}
        {IMAGES_LIST.length > MIN_IMAGES_NUMBER && (
          <Grid item xl={12} md={12} sm={12} xs={12} marginTop={"24px"} display={"flex"} justifyContent={"center"}>
            <button onClick={onShowMore} className={ctaButtonClasses}>
              {(showImagesCount > MIN_IMAGES_NUMBER && showImagesCount < IMAGES_LIST.length) ||
              showImagesCount === MIN_IMAGES_NUMBER
                ? "Arata mai multe"
                : "Arata mai putine"}
            </button>
          </Grid>
        )}
      </Grid>
      <Dialog open={modalOpen} onClose={handleModalClose} fullWidth={true} maxWidth={"sm"}>
        <Box sx={style}>
          {selectedImageIndex >= 0 && (
            <img width="100%" height="100%" src={IMAGES_LIST[selectedImageIndex].img} alt="" />
          )}
          <div className={navStyles.navBtn}>
            <IconButton onClick={prevImage}>
              <ChevronLefttIcon sx={{ fontSize: "50px", color: "#FFF" }} />
            </IconButton>
            <IconButton onClick={nextImage}>
              <ChevronRightIcon sx={{ fontSize: "50px", color: "#FFF" }} />
            </IconButton>
          </div>
        </Box>
      </Dialog>
    </SectionContainer>
  )
}

const style = {
  bgcolor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const navStyles = stylesheet({
  navBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
})
