import React from "react"
import { stylesheet } from "typestyle"

import { Grid } from "@mui/material"
import { SectionIcon } from "../SectionIcon/SectionIcon"
import { SectionContainer } from "../SectionContainer/SectionContainer"

import mcLocation from "../../assets/images/mastercut-building.png"

import { LocationMap } from "../LocationMap/LocationMap"
import { SectionType } from "../../shared/types"

const SECTION_DESCRIPTION = `Locația noastră este centrală, în vecinătatea Cluj Arena, Sala Polivalentă, USAMV, Platinia, etc. Suntem
            ușor accesibili din orice parte a orașului, iar dacă veniți cu mașina, o
            <strong> puteți parca în locurile de parcare din vecinătatea salonului nostru.</strong>`

export const ContactSection = ({ id }: SectionType): JSX.Element => {
  return (
    <SectionContainer
      id={id}
      icon={{ icon: "info", text: "Contact si adresă" }}
      iconMargin={"0 0 24px"}
      description={SECTION_DESCRIPTION}
    >
      <Grid container>
        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          bgcolor={"#CC8C1F"}
          color={"#fff"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={"36px 24px"}
        >
          <p className={styles.address}>
            Str. Uzinei electrice, nr. 1 <br /> Cluj-Napoca <br />
            <strong>0731 423 820</strong>
          </p>
        </Grid>
        <Grid item md={7} sm={12} xs={12} height={{ md: "257px", sm: "153px", xs: "153px" }}>
          <div className={styles.location} />
        </Grid>
        <Grid
          container
          item
          md={5}
          sm={12}
          xs={12}
          bgcolor={"#DDDDDD"}
          color={"#fff"}
          padding={{ md: "72px 50px", sm: "16px 24px", xs: "16px 24px" }}
        >
          <Grid item display={"flex"} md={12} sm={6} xs={6}>
            <SectionIcon icon={"map-pin"} text={"Locație centrală"} className={styles.locationDetails} />
          </Grid>
          <Grid item display={"flex"} md={12} sm={6} xs={6}>
            <SectionIcon icon={"parking"} text={"Parcare clienți"} className={styles.locationDetails} />
          </Grid>
        </Grid>
        <Grid item md={7} sm={12} xs={12} height={{ md: "257px", sm: "153px", xs: "153px" }}>
          <LocationMap />
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

const styles = stylesheet({
  location: {
    width: "100%",
    height: "100%",
    background: `url(${mcLocation}) no-repeat center center`,
    backgroundSize: "cover",
  },
  address: {
    color: "#fff",
    fontSize: "16px",
    margin: "0",
  },
  locationDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    // margin: "12px",
    $nest: {
      span: {
        marginLeft: "16px",
        color: "#100F17",
        fontSize: "16px",
        lineHeight: "140%",
      },
    },
  },
})
