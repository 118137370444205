import React from "react"
import { IconType } from "./Icons.type"
import { useWindowSize } from "../../helpers/customHooks"

export const ServicesIcon = ({ width, height, background, fill, className }: IconType): JSX.Element => {
  const { isMobile } = useWindowSize()
  return (
    <svg
      className={className}
      width={width || isMobile ? "36" : "48"}
      height={height || isMobile ? "36" : "48"}
      viewBox="0 0 48 48"
      fill={background || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill={fill || "#DCB675"} />
      <path
        d="M24.6198 8C24.9248 8.06 25.2298 8.11 25.5298 8.185C26.4625 8.40104 27.3371 8.81692 28.0933 9.40394C28.8495 9.99095 29.4693 10.7351 29.9098 11.585C29.9748 11.7 30.0248 11.825 30.0998 11.975H17.8398C18.1298 11.515 18.3398 11.065 18.6648 10.665C19.204 9.9387 19.8873 9.33155 20.6719 8.88158C21.4566 8.4316 22.3257 8.1485 23.2248 8.05C23.2739 8.04087 23.3211 8.02399 23.3648 8H24.6198Z"
        fill="#BE7800"
      />
      <path
        d="M32 13.35V16C31.65 16 31.31 16 30.97 16C30.82 16 30.69 16 30.615 16.165C30.585 16.235 30.505 16.285 30.445 16.345L17.585 29.18L17.31 29.43V29.07C17.31 28.07 17.31 27.07 17.31 26.04C17.3057 25.9394 17.3231 25.8392 17.3611 25.746C17.3991 25.6528 17.4567 25.5689 17.53 25.5C20.6 22.45 23.6633 19.395 26.72 16.335C26.8139 16.2512 26.9124 16.1727 27.015 16.1L27 16C26.915 16 26.83 16 26.745 16C25.68 16 24.615 16 23.555 16C23.3849 16.0083 23.2234 16.0775 23.1 16.195C21.2567 18.0116 19.4267 19.8316 17.61 21.655L17.32 21.925V16H16V13.35H32Z"
        fill="#BE7800"
      />
      <path
        d="M30.68 20C30.68 21.03 30.68 22.055 30.68 23.085C30.6901 23.2554 30.6621 23.426 30.5982 23.5842C30.5342 23.7425 30.4357 23.8845 30.31 24C27.72 26.5567 25.1417 29.1234 22.575 31.7C22.4894 31.7755 22.3992 31.8456 22.305 31.91L22.345 31.985C22.43 31.985 22.515 31.985 22.6 31.985C23.655 31.985 24.71 31.985 25.765 31.985C25.8583 31.9901 25.9516 31.9743 26.0381 31.9389C26.1246 31.9034 26.2021 31.8491 26.265 31.78C27.63 30.405 29.01 29.04 30.38 27.665C30.4502 27.5748 30.5137 27.4795 30.57 27.38L30.68 27.45V32H32V34.66H16V32H17.165C17.6181 32.0606 18.0783 32.042 18.525 31.945C18.91 31.79 19.18 31.355 19.525 31.035L30.345 20.21C30.4251 20.1128 30.497 20.0091 30.56 19.9L30.68 20Z"
        fill="#BE7800"
      />
      <path
        d="M17.9141 36H30.0441C29.8191 37.72 26.9391 39.79 24.4641 39.97C23.0946 40.0739 21.727 39.7471 20.5525 39.0352C19.378 38.3234 18.4555 37.2621 17.9141 36Z"
        fill="#BE7800"
      />
    </svg>
  )
}
