import React from "react"

export const InstagramIcon = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4453 18C21.4453 19.9028 19.9028 21.4453 18 21.4453C16.0972 21.4453 14.5547 19.9028 14.5547 18C14.5547 16.0972 16.0972 14.5547 18 14.5547C19.9028 14.5547 21.4453 16.0972 21.4453 18Z"
        fill="white"
      />
      <path
        d="M26.0546 11.9043C25.8889 11.4555 25.6247 11.0493 25.2814 10.7159C24.948 10.3726 24.542 10.1083 24.093 9.94272C23.7288 9.80127 23.1816 9.6329 22.1739 9.58704C21.0838 9.53732 20.757 9.52661 17.9972 9.52661C15.2372 9.52661 14.9103 9.53705 13.8205 9.58676C12.8127 9.6329 12.2654 9.80127 11.9014 9.94272C11.4524 10.1083 11.0461 10.3726 10.713 10.7159C10.3697 11.0493 10.1054 11.4553 9.93954 11.9043C9.7981 12.2685 9.62973 12.8159 9.58386 13.8236C9.53415 14.9135 9.52344 15.2403 9.52344 18.0004C9.52344 20.7601 9.53415 21.087 9.58386 22.1771C9.62973 23.1848 9.7981 23.7319 9.93954 24.0961C10.1054 24.5452 10.3694 24.9511 10.7127 25.2846C11.0461 25.6279 11.4521 25.8921 11.9012 26.0577C12.2654 26.1995 12.8127 26.3678 13.8205 26.4137C14.9103 26.4634 15.2369 26.4738 17.9969 26.4738C20.7572 26.4738 21.0841 26.4634 22.1736 26.4137C23.1814 26.3678 23.7288 26.1995 24.093 26.0577C24.9944 25.71 25.7068 24.9976 26.0546 24.0961C26.196 23.7319 26.3644 23.1848 26.4105 22.1771C26.4602 21.087 26.4707 20.7601 26.4707 18.0004C26.4707 15.2403 26.4602 14.9135 26.4105 13.8236C26.3647 12.8159 26.1963 12.2685 26.0546 11.9043ZM17.9972 23.3076C15.0658 23.3076 12.6894 20.9315 12.6894 18.0001C12.6894 15.0687 15.0658 12.6926 17.9972 12.6926C20.9283 12.6926 23.3047 15.0687 23.3047 18.0001C23.3047 20.9315 20.9283 23.3076 17.9972 23.3076ZM23.5145 13.7231C22.8295 13.7231 22.2742 13.1678 22.2742 12.4828C22.2742 11.7978 22.8295 11.2424 23.5145 11.2424C24.1995 11.2424 24.7549 11.7978 24.7549 12.4828C24.7546 13.1678 24.1995 13.7231 23.5145 13.7231Z"
        fill="white"
      />
      <path
        d="M18 0C8.06039 0 0 8.06039 0 18C0 27.9396 8.06039 36 18 36C27.9396 36 36 27.9396 36 18C36 8.06039 27.9396 0 18 0ZM28.2736 22.2613C28.2236 23.3616 28.0486 24.1128 27.7932 24.7703C27.2563 26.1587 26.1587 27.2563 24.7703 27.7932C24.1131 28.0486 23.3616 28.2233 22.2616 28.2736C21.1594 28.3239 20.8073 28.3359 18.0003 28.3359C15.193 28.3359 14.8412 28.3239 13.7387 28.2736C12.6387 28.2233 11.8872 28.0486 11.2299 27.7932C10.54 27.5337 9.91544 27.1269 9.39908 26.6009C8.87338 26.0848 8.46661 25.46 8.20706 24.7703C7.95163 24.1131 7.77667 23.3616 7.72668 22.2616C7.67587 21.1591 7.66406 20.807 7.66406 18C7.66406 15.193 7.67587 14.8409 7.72641 13.7387C7.7764 12.6384 7.95108 11.8872 8.20651 11.2297C8.46606 10.54 8.87311 9.91516 9.39908 9.39908C9.91516 8.87311 10.54 8.46634 11.2297 8.20679C11.8872 7.95135 12.6384 7.77667 13.7387 7.72641C14.8409 7.67615 15.193 7.66406 18 7.66406C20.807 7.66406 21.1591 7.67615 22.2613 7.72668C23.3616 7.77667 24.1128 7.95135 24.7703 8.20651C25.46 8.46606 26.0848 8.87311 26.6012 9.39908C27.1269 9.91544 27.5339 10.54 27.7932 11.2297C28.0489 11.8872 28.2236 12.6384 28.2739 13.7387C28.3241 14.8409 28.3359 15.193 28.3359 18C28.3359 20.807 28.3241 21.1591 28.2736 22.2613Z"
        fill="white"
      />
    </svg>
  )
}
