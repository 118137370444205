import React from "react"
import { IconType } from "./Icons.type"
import { useWindowSize } from "../../helpers/customHooks"

export const WalletIcon = ({ width, height }: IconType): JSX.Element => {
  const { isMobile } = useWindowSize()
  return (
    <svg
      width={width || isMobile ? "36" : "48"}
      height={height || isMobile ? "36" : "48"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#DCB675" />
      <path
        d="M34.4482 36H12.2862C12.2443 35.9838 12.2014 35.9701 12.1579 35.9592C11.535 35.8591 10.9728 35.5278 10.5832 35.0315C10.2798 34.6444 10.0792 34.1868 10 33.7013V17.9139C10 17.9139 10.0292 17.8847 10.0292 17.8672C10.111 17.219 10.4374 16.6266 10.9415 16.2112C11.4457 15.7958 12.0894 15.5889 12.7411 15.6327C13.3243 15.6327 13.855 15.6327 14.4091 15.6327C14.6134 15.6237 14.8129 15.5678 14.9923 15.4694C19.0514 13.1318 23.0834 10.7903 27.0881 8.44497C27.6045 8.10444 28.2222 7.952 28.8377 8.01324C29.2603 8.06713 29.6634 8.22337 30.012 8.46839C30.3606 8.71341 30.6442 9.03981 30.8381 9.41929C31.9695 11.4029 33.1029 13.3885 34.2382 15.376C34.2818 15.4685 34.3489 15.5479 34.4329 15.6063C34.5168 15.6647 34.6146 15.6999 34.7165 15.7086C35.2337 15.8094 35.7053 16.0724 36.0629 16.4595C36.4205 16.8466 36.6455 17.3377 36.7052 17.8614C36.746 18.5323 36.7402 19.2091 36.746 19.88C36.7519 20.551 36.5128 20.726 35.9062 20.726C34.5473 20.726 33.1885 20.726 31.8237 20.726C31.5059 20.7251 31.1884 20.7446 30.8731 20.7844C29.5988 20.9781 28.4456 21.6487 27.6466 22.6606C26.8477 23.6725 26.4626 24.95 26.5693 26.235C26.6759 27.5201 27.2664 28.7166 28.2212 29.5828C29.1761 30.4489 30.4241 30.92 31.7129 30.9009C33.1243 30.9009 34.5415 30.9009 35.9529 30.9009C36.4953 30.9009 36.7344 31.1401 36.7402 31.6827C36.746 32.2253 36.7402 32.7912 36.7402 33.3513C36.7496 33.7627 36.6609 34.1705 36.4814 34.5408C36.3019 34.9112 36.0367 35.2334 35.7079 35.4808C35.3322 35.7461 34.9017 35.9236 34.4482 36ZM29.6134 9.90936C29.5306 9.75656 29.4164 9.62303 29.2784 9.51754C29.1403 9.41205 28.9815 9.33699 28.8124 9.29731C28.6433 9.25763 28.4676 9.25423 28.2971 9.28732C28.1266 9.3204 27.965 9.38924 27.8229 9.4893L17.5351 15.481C17.4826 15.5102 17.4359 15.5569 17.331 15.6327C18.095 15.6327 18.7832 15.6327 19.4772 15.6327C19.6806 15.6324 19.8808 15.5823 20.0604 15.4869C22.2299 14.2325 24.3878 12.9723 26.5515 11.7121L29.6134 9.90936ZM32.9027 15.6211L31.5205 13.2057L27.438 15.5744V15.6211H32.9027Z"
        fill="#BE7800"
      />
      <path
        d="M33.7696 21.9979C34.4986 21.9979 35.2276 21.9979 35.9566 21.9979C36.2278 21.9809 36.4996 22.0217 36.7538 22.1176C37.0081 22.2136 37.2391 22.3624 37.4315 22.5544C37.6239 22.7463 37.7734 22.977 37.87 23.2311C37.9667 23.4851 38.0083 23.7569 37.992 24.0282V27.5813C38.0142 27.8446 37.9807 28.1097 37.8938 28.3593C37.8068 28.6089 37.6684 28.8373 37.4875 29.0299C37.3065 29.2225 37.0871 29.3748 36.8434 29.477C36.5998 29.5793 36.3374 29.6291 36.0733 29.6232C34.4986 29.6641 32.9239 29.6757 31.3493 29.6232C30.4138 29.5571 29.5359 29.1469 28.8847 28.4717C28.2335 27.7966 27.8552 26.9042 27.8226 25.9666C27.79 25.029 28.1055 24.1125 28.7082 23.3937C29.3109 22.675 30.1582 22.2048 31.0868 22.0737C31.9765 22.0241 32.8682 22.0241 33.7579 22.0737L33.7696 21.9979ZM31.635 24.5475C31.3135 24.5693 31.0122 24.7125 30.7921 24.948C30.5721 25.1836 30.4497 25.494 30.4497 25.8164C30.4497 26.1388 30.5721 26.4492 30.7921 26.6848C31.0122 26.9203 31.3135 27.0635 31.635 27.0853C31.8088 27.0971 31.9832 27.0731 32.1474 27.0146C32.3115 26.9562 32.4619 26.8647 32.5891 26.7457C32.7164 26.6267 32.8179 26.4828 32.8873 26.3229C32.9567 26.1631 32.9925 25.9907 32.9925 25.8164C32.9925 25.6421 32.9567 25.4697 32.8873 25.3099C32.8179 25.15 32.7164 25.0061 32.5891 24.8871C32.4619 24.7681 32.3115 24.6766 32.1474 24.6182C31.9832 24.5597 31.8088 24.5357 31.635 24.5475Z"
        fill="#BE7800"
      />
    </svg>
  )
}
