import React from "react"
import classNames from "classnames"
import { Link } from "react-scroll"

import { CloseIcon, MastercutLogo, MenuIcon } from "../Icons"
import { mobileNavStyles, mobileNav } from "./MobileNav.module"
import { stylerUrl } from "../../shared/stylerUrl"
import { commonStyles } from "../../typestyles/CommonStyles.module"

type MobileNavProps = {
  isOpened: boolean
  toggleMenu: () => void
}

const MENU_ITEMS = [
  { title: "Orar", to: "schedule" },
  { title: "Servicii", to: "services" },
  { title: "Prețuri", to: "prices" },
  { title: "Galerie imagini", to: "gallery" },
  { title: "Despre noi", to: "about" },
  { title: "Contact", to: "contact" },
]

export const MobileNav = ({ isOpened, toggleMenu }: MobileNavProps): JSX.Element => {
  const mobileNavClasses = classNames(mobileNav, {
    [commonStyles.displayNone]: isOpened,
  })
  const closeIconClasses = classNames(mobileNavStyles.closeNavIcon, {
    [commonStyles.displayBlock]: isOpened,
    [commonStyles.displayNone]: !isOpened,
  })
  const mobileMenuClasses = classNames(mobileNavStyles.topNav, {
    [commonStyles.displayNone]: !isOpened,
  })

  const ctaButtonClasses = classNames(commonStyles.btn, commonStyles.btnPrimary)

  return (
    <>
      <div className={mobileNavClasses}>
        <span className={mobileNavStyles.navIcon} onClick={toggleMenu}>
          <MenuIcon />
        </span>
        <a href={stylerUrl} target={"_blank"} className={ctaButtonClasses}>
          Programare online
        </a>
      </div>
      <span className={closeIconClasses} onClick={toggleMenu}>
        <CloseIcon />
      </span>
      <div className={mobileMenuClasses}>
        <div>
          <MastercutLogo />
          <ul className={mobileNavStyles.mobileMenuList}>
            {MENU_ITEMS.map((menuItem) => (
              <li key={menuItem.title}>
                <Link to={menuItem.to} activeClass="active" offset={-64} spy={true} smooth={true} onClick={toggleMenu}>
                  {menuItem.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}
