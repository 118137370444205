import React from "react"
import classNames from "classnames"

import { MastercutLogo } from "../Icons"

import { heroImage, heroCta } from "./Hero.module"
import { commonStyles } from "../../typestyles/CommonStyles.module"
import { Grid } from "@mui/material"
import { stylerUrl } from "../../shared/stylerUrl"

const ctaButtonClasses = classNames(commonStyles.btn, commonStyles.btnPrimary, heroCta)

export const Hero = (): JSX.Element => {
  return (
    <>
      <div data-testid="hero-image" className={`${heroImage}`}>
        <Grid item width={{ md: "270px", sm: "200px", xs: "200px" }} marginTop={"64px"}>
          <MastercutLogo width={"100%"} height={"100%"} />
        </Grid>
        <a href={stylerUrl} target={"_blank"} className={ctaButtonClasses}>
          Programare online
        </a>
      </div>
    </>
  )
}
