import { media, style, stylesheet } from "typestyle"

export const mobileNavStyles = stylesheet({
  navIcon: {
    cursor: "pointer",
    backgroundColor: "transparent",
    padding: 0,
  },
  closeNavIcon: {
    position: "absolute",
    top: "24px",
    right: 0,
    marginRight: "24px",
    zIndex: 100,
    cursor: "pointer",
  },
  mobileMenuList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    minWidth: "100px",
    textAlign: "center",
    $nest: {
      li: {
        display: "block",
        margin: "24px 0",
      },
      "li a": {
        fontFamily: `"Chivo", "Open Sans", "sans-serif"`,
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "24px",
        textDecoration: "none",
        color: "#fff",
        cursor: "pointer",
      },
    },
  },
  topNav: {
    padding: "40px 24px 24px",
    display: "flex",
    justifyContent: "center",
    height: "100%",
    boxSizing: "border-box",
  },
})

export const mobileNav = style(
  {
    alignItems: "center",
    height: "100%",
    justifyContent: "space-between",
    display: "none",
    margin: "0 24px",
  },
  media({ maxWidth: 767 }, { display: "flex" })
)
