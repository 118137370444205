import React from "react"
import { Hero } from "../Hero/Hero"
import { NavigationBar } from "../NavigationBar/NavigationBar"
import { ScheduleSection } from "../ScheduleSection/ScheduleSection"
import { ServicesSection } from "../ServicesSection/ServicesSection"
import { SectionContainer } from "../SectionContainer/SectionContainer"
import { PricesSection } from "../PricesSection/PricesSection"
import { ImagesSection } from "../ImageSection/ImagesSection"
import { AboutSection } from "../AboutSection/AboutSection"
import { Footer } from "../Footer/Footer"
import { ContactSection } from "../ContactSection/ContactSection"
import { DevnestSection } from "../DevnestSection/DevnestSection"
import imageBg from "../../assets/background-1.jpg"

export const App = (): JSX.Element => {
  return (
    <>
      <NavigationBar />
      <Hero />
      <ScheduleSection id="schedule" />
      <ServicesSection id="services" />
      <SectionContainer
        padding={{ md: "48px", xs: "28px" }}
        styles={{
          background: `url(${imageBg}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      />
      <PricesSection id="prices" />
      <ImagesSection id="gallery" />
      <AboutSection id="about" />
      <ContactSection id="contact" />
      <DevnestSection />
      <Footer />
    </>
  )
}
