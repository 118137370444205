import { stylesheet } from "typestyle"

export const commonStyles = stylesheet({
  displayBlock: {
    display: "block !important",
  },
  displayNone: {
    display: "none !important",
  },
  displayFlex: {
    display: "flex !important",
  },
  displayFlexStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  displayFlexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  displayFlexBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  vHeight80: {
    height: "80vh !important",
  },
  marginX24: {
    margin: "0 24px !important",
  },
  marginRight5: {
    marginRight: "5px",
  },
  marginNone: {
    margin: "0 !important",
  },
  marginX56: {
    margin: "0 56px !important",
  },
  backgroundLightGray: {
    backgroundColor: "#F5F5F5 !important",
  },
  backgroundWhite: {
    backgroundColor: "#fff !important",
  },
  btn: {
    fontFamily: "'Chivo', sans-serif",
    textAlign: "center",
    fontSize: "12px",
    padding: "10px 24px",
    cursor: "pointer",
    textDecoration: "none",
  },
  btnPrimary: {
    background: "#cc8c1f",
    borderRadius: "4px",
    border: "1px solid #cc8c1f",
    color: "#ffffff",
    $nest: {
      "&:hover": {
        background: "#be7800",
      },
      "&:active": {
        background: "#e3ad17",
      },
      "&:disabled": {
        background: "#e5c58f",
      },
    },
  },
  btnSecondary: {
    color: "#cc8c1f",
    background: "#fff",
    borderRadius: "4px",
    width: "160px",
    border: "1px solid #cc8c1f",
    $nest: {
      "&:hover": {
        background: "#be7800",
        color: "#ffffff",
      },
      "&:active": {
        background: "#e3ad17",
      },
      "&:disabled": {
        background: "#e5c58f",
      },
    },
  },
  bgGray: {
    backgroundColor: "#F5F5F5",
  },
  bgDark: {
    backgroundColor: "#0D0D0D",
  },
  title: {
    fontSize: "24px",
    lineHeight: "100%",
    fontWeight: "400",
    margin: 0,
  },
  description: {
    fontSize: "16px",
    color: "#100f17",
    lineHeight: "170%",
    margin: "24px 0",
  },
  cutText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    lineHeight: "normal",
  },
  displayInline: {
    display: "inline",
  },
  showMoreButton: {
    display: "inline-flex",
    alignItems: "center",
    color: "#0A06E8",
    cursor: "pointer",
    fontSize: "14px",
    lineHeight: "130%",
  },
  rotate180: {
    transform: "rotate(180deg)",
  },
  serviceTitle: {
    lineHeight: "130%",
    margin: "0",
  },
  serviceDescription: {
    fontSize: "14px",
    marginRight: "5px",
    lineHeight: "130%",
    margin: "0",
  },
})
