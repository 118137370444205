import React from "react"
import { IconType } from "./Icons.type"

export const MapPinIcon = ({ width, height }: IconType): JSX.Element => {
  return (
    <svg
      width={width || "26"}
      height={height || "36"}
      viewBox="0 0 26 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8159 8.96003C24.6877 8.47977 24.4275 7.96804 24.235 7.52C21.9314 1.984 16.8982 0 12.8341 0C7.39363 0 1.40156 3.64809 0.601562 11.1677V12.704C0.601562 12.7681 0.623659 13.344 0.655046 13.6321C1.1035 17.2157 3.93127 21.0244 6.04315 24.608C8.31524 28.4475 10.6729 32.2245 13.0086 35.9999C14.4489 33.5362 15.884 31.04 17.2915 28.64C17.6751 27.9357 18.1204 27.2318 18.5044 26.5595C18.7603 26.1119 19.2493 25.6643 19.4727 25.2478C21.7447 21.0881 25.4017 16.8962 25.4017 12.768V11.0721C25.4018 10.6246 24.8471 9.05654 24.8159 8.96003ZM12.9337 16.6722C11.3345 16.6722 9.58403 15.8725 8.72001 13.6642C8.59128 13.3126 8.60166 12.6081 8.60166 12.5436V11.5516C8.60166 8.73631 10.9921 7.45605 13.0717 7.45605C15.6318 7.45605 17.6119 9.50433 17.6119 12.0645C17.6119 14.6246 15.4939 16.6722 12.9337 16.6722Z"
        fill="#362C2C"
      />
    </svg>
  )
}
