import React from "react"
import { stylesheet } from "typestyle"

import { CalendarIcon, WalletIcon, ServicesIcon, GroupIcon, InfoIcon, MapPinIcon, ParkingIcon } from "../Icons"

type SectionIconType = "calendar" | "wallet" | "services" | "group" | "info" | "map-pin" | "parking"

export type SectionIconProps = {
  icon: SectionIconType
  text: string
  className?: string
}

export const SectionIcon = ({ icon, text, className }: SectionIconProps): JSX.Element => {
  const displayIcon = () => {
    switch (icon) {
      case "calendar":
        return <CalendarIcon />
      case "services":
        return <ServicesIcon />
      case "wallet":
        return <WalletIcon />
      case "group":
        return <GroupIcon />
      case "info":
        return <InfoIcon />
      case "map-pin":
        return <MapPinIcon width={"36px"} />
      case "parking":
        return <ParkingIcon />
      default:
        return <CalendarIcon />
    }
  }
  return (
    <div className={className || section.iconTextSection}>
      {displayIcon()} <span>{text}</span>
    </div>
  )
}

const section = stylesheet({
  iconTextSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    $nest: {
      span: {
        fontFamily: "Chivo, 'Open Sans', sans-serif",
        marginLeft: "15px",
        color: "#100F17",
        fontSize: "24px",
        letterSpacing: "0.03em",
        fontWeight: "600",
      },
    },
  },
})
