import React from "react"
import { stylesheet } from "typestyle"

import { SectionContainer } from "../SectionContainer/SectionContainer"
import Grid from "@mui/material/Grid"
import { GromedManIcon } from "../Icons/GromedManIcon"
import { SectionType } from "../../shared/types"

import bulletImage from "../../assets/elipse.svg"

import { commonStyles } from "../../typestyles/CommonStyles.module"

const sectionDescription = `MasterCut a fost creat cu o înțelegere profundă a ceea ce înseamnă 
            <strong>timp, confort și experiențe personalizate</strong>. De aceea, ne-am asigurat ca echipa noastră să reflecte
            aceste lucruri în orice interacțiune cu clienții noștri, 
            <strong>iar timpul petrecut în salonul nostru să fie unul de calitate</strong>. Serviciile noastre reflectă toate
            acestea.`

export const ServicesSection = ({ id }: SectionType) => {
  return (
    <SectionContainer
      id={id}
      backgroundColor={"light"}
      icon={{ icon: "services", text: "Servicii" }}
      description={sectionDescription}
    >
      <Grid container>
        <Grid item xl={7} md={7} sm={12} xs={12} order={{ md: 1, sm: 2, xs: 2 }}>
          <p>
            <strong>Puteți opta pentru:</strong>
          </p>
          <ul className={servicesStyles.servicesList}>
            <li className={servicesStyles.servicesListItem}>aranjat și tuns barba;</li>
            <li className={servicesStyles.servicesListItem}>styling și tuns părul;</li>
            <li className={servicesStyles.servicesListItem}>epilare și tratamente faciale cu ceară;</li>
            <li className={servicesStyles.servicesListItem}>bărbierit cu prosop cald sau vapozon;</li>
            <li className={servicesStyles.servicesListItem}>vopsit părul sau barba;</li>
            <li className={servicesStyles.servicesListItem}>masaj capilar și facial;</li>
            <li className={servicesStyles.servicesListItem}>spălare la scafă cu masaj.</li>
          </ul>
        </Grid>
        <Grid
          item
          xl={5}
          md={5}
          sm={12}
          xs={12}
          order={{ md: 1, sm: 1, xs: 1 }}
          padding={2}
          className={commonStyles.displayFlexStart}
        >
          <GromedManIcon />
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

const servicesStyles = stylesheet({
  servicesList: {
    listStyleType: "none",
    listStylePosition: "inside",
    margin: "0",
    padding: "0",
  },
  servicesListItem: {
    // background: `url(data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" fill="#DCB675"/></svg>)`,
    background: `url(${bulletImage})  no-repeat top left`,
    marginBottom: "12px",
    backgroundPosition: "0 5px !important",
    padding: "0 0 4px 20px !important",
  },
})
