import image_1 from "../../assets/images/1.png"
import image_2 from "../../assets/images/2.png"
import image_3 from "../../assets/images/3.png"
import image_4 from "../../assets/images/4.png"
import image_5 from "../../assets/images/5.png"
import image_6 from "../../assets/images/6.png"
import image_7 from "../../assets/images/7.png"
import image_8 from "../../assets/images/8.png"
import image_9 from "../../assets/images/9.png"
import image_10 from "../../assets/images/10.png"
import image_11 from "../../assets/images/11.png"
import image_12 from "../../assets/images/12.png"
import image_13 from "../../assets/images/13.png"
import image_14 from "../../assets/images/14.png"
import image_15 from "../../assets/images/15.png"
import image_16 from "../../assets/images/16.png"
import image_17 from "../../assets/images/17.png"
import image_18 from "../../assets/images/18.png"

export type ListImageType = {
  img: string
  title: string
}

export const IMAGES_LIST: ListImageType[] = [
  {
    img: image_1,
    title: "MasterCur gallery image",
  },
  {
    img: image_2,
    title: "MasterCur gallery image",
  },
  {
    img: image_3,
    title: "MasterCur gallery image",
  },
  {
    img: image_4,
    title: "MasterCur gallery image",
  },
  {
    img: image_5,
    title: "MasterCur gallery image",
  },
  {
    img: image_6,
    title: "MasterCur gallery image",
  },
  {
    img: image_7,
    title: "MasterCur gallery image",
  },
  {
    img: image_8,
    title: "MasterCur gallery image",
  },
  {
    img: image_9,
    title: "MasterCur gallery image",
  },
  {
    img: image_10,
    title: "MasterCur gallery image",
  },
  {
    img: image_11,
    title: "MasterCur gallery image",
  },
  {
    img: image_12,
    title: "MasterCur gallery image",
  },
  {
    img: image_13,
    title: "MasterCur gallery image",
  },
  {
    img: image_14,
    title: "MasterCur gallery image",
  },
  {
    img: image_15,
    title: "MasterCur gallery image",
  },
  {
    img: image_16,
    title: "MasterCur gallery image",
  },
  {
    img: image_17,
    title: "MasterCur gallery image",
  },
  {
    img: image_18,
    title: "MasterCur gallery image",
  },
]
