import React from "react"

export const ParkingIcon = (): JSX.Element => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5418 0H3.46038C1.33819 0 0 1.33592 0 3.45685V32.5389C0 34.6609 1.33819 36 3.45716 36H32.5418C34.665 36 36.0021 34.6641 36.0021 32.5421C36.0021 22.8484 36.0021 13.1544 36.0021 3.46007C36 1.33807 34.664 0 32.5418 0ZM20.8482 20.4146C20.0823 20.5399 19.3064 20.5931 18.5305 20.5736C17.1913 20.5682 15.852 20.5811 14.5128 20.5639C14.2131 20.5639 14.1347 20.6369 14.1358 20.9387C14.1476 23.9391 14.1433 26.9385 14.1433 29.939C14.1433 30.6735 13.9575 30.8571 13.2143 30.8571C12.0888 30.8571 10.9636 30.8571 9.83878 30.8571C9.2105 30.8571 9.00107 30.6424 9.00107 30.0098C9.00107 26.0057 9.00107 22.0015 9.00107 17.9973V6.06425C9.00107 5.32542 9.18472 5.14286 9.92577 5.14286C12.9791 5.14286 16.0325 5.14286 19.0858 5.14286C22.9403 5.15145 26.1204 7.78355 26.8647 11.5701C27.6465 15.5574 24.8424 19.6737 20.8482 20.4146Z"
        fill="#362C2C"
      />
      <path
        d="M16.9434 10.2858C16.1132 10.2858 15.2841 10.2954 14.455 10.2858C14.2101 10.2858 14.1328 10.3459 14.1328 10.5972C14.145 12.1092 14.145 13.6213 14.1328 15.1333C14.1328 15.3921 14.2177 15.4405 14.455 15.4394C16.0606 15.4297 17.6662 15.4394 19.2718 15.4319C19.6137 15.4385 19.9534 15.3768 20.2712 15.2505C20.5889 15.1242 20.8782 14.9358 21.1222 14.6962C21.3662 14.4567 21.5599 14.1709 21.692 13.8556C21.8241 13.5403 21.892 13.2017 21.8917 12.8598C21.8913 12.5179 21.8227 12.1796 21.6899 11.8645C21.5571 11.5495 21.3628 11.2641 21.1183 11.0251C20.8739 10.786 20.5841 10.5982 20.2662 10.4726C19.9482 10.3469 19.6083 10.286 19.2665 10.2933C18.4953 10.2804 17.7221 10.2858 16.9434 10.2858Z"
        fill="#362C2C"
      />
    </svg>
  )
}
