import React from "react"
import { Grid } from "@mui/material"
import { stylesheet } from "typestyle"

import { MastercutLogo, FacebookIcon, InstagramIcon } from "../Icons"

const FACEBOOK_URL = "https://www.facebook.com/mastercut2022/"
const INSTAGRAM_URL = "https://www.instagram.com/_mastercut_/"

export const Footer = (): JSX.Element => {
  return (
    <footer className={styles.footer}>
      <Grid container spacing={2} maxWidth={{ md: "80%", sm: "100%", xs: "100%" }}>
        <Grid item md={4} sm={4} xs={12} textAlign={"center"}>
          <MastercutLogo />
        </Grid>
        <Grid item md={4} sm={4} xs={12} textAlign={{ xs: "center", sm: "left", md: "left" }}>
          <p className={styles.contactDetails}>Contact</p>
          <p className={styles.contactAddress}>
            Str. Uzinei electrice, nr. 1 Cluj-Napoca <br />
            0731 423 820
          </p>
        </Grid>
        <Grid item md={4} sm={4} xs={12} textAlign={{ xs: "center", sm: "left", md: "left" }}>
          <div className={styles.socialMedia}>
            <a href={FACEBOOK_URL} target={"_blank"}>
              <FacebookIcon />
            </a>
            <a href={INSTAGRAM_URL} target={"_blank"}>
              <InstagramIcon />
            </a>
          </div>
        </Grid>
      </Grid>
    </footer>
  )
}

const styles = stylesheet({
  footer: {
    backgroundColor: "#0D0D0D",
    padding: "36px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contactDetails: {
    fontSize: "14px",
    color: "#fff",
    marginBottom: "12px",
    textTransform: "uppercase",
  },
  contactAddress: {
    fontSize: "16px",
    color: "#fff",
    lineHeight: "170%",
    margin: "0",
  },
  socialMedia: {
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    height: "100%",
    $nest: {
      svg: {
        margin: "0 18px",
      },
    },
  },
})
