import React from "react"
import { IconType } from "./Icons.type"

export const MenuIcon = ({ width, height, background, fill, className }: IconType): JSX.Element => {
  return (
    <svg
      className={className}
      width={width || "33"}
      height={height || "24"}
      viewBox="0 0 33 24"
      fill={background || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="33" height="3" rx="1.5" fill={fill || "white"} />
      <rect y="10.5" width="33" height="3" rx="1.5" fill={fill || "white"} />
      <rect y="21" width="33" height="3" rx="1.5" fill={fill || "white"} />
    </svg>
  )
}
