import { media, style, stylesheet } from "typestyle"

export const topNav = style(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: "0 20px",
  },
  media({ maxWidth: 767 }, { display: "none" })
)

export const styles = stylesheet({
  topNavWrapper: {
    backgroundColor: "#2D2D2D",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "64px",
    borderTop: "4px solid #CD8C1E",
    boxSizing: "border-box",
    zIndex: 1000,
  },
  navLink: {
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "#fff",
    cursor: "pointer",
    letterSpacing: "0.1em",
  },
  navItem: {
    marginRight: "36px",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  mobileNav: {
    display: "flex",
    alignItems: "center",
    height: "64px",
    justifyContent: "space-between",
  },
  navIcon: {
    marginLeft: "24px",
    cursor: "pointer",
    backgroundColor: "transparent",
    padding: 0,
  },
  closeNavIcon: {
    position: "absolute",
    right: 0,
    marginRight: "24px",
  },
})
