import React from "react"
import { Grid } from "@mui/material"

import { SectionIcon, SectionIconProps } from "../SectionIcon/SectionIcon"
import { commonStyles } from "../../typestyles/CommonStyles.module"

type BackgroundColorTypes = "light" | "white"

type PaddingTypes = { xl?: string; md?: string; sm?: string; xs?: string } | string

type SectionContainerProps = {
  id?: string
  icon?: SectionIconProps
  description?: string
  iconMargin?: string
  backgroundColor?: BackgroundColorTypes
  padding?: PaddingTypes
  styles?: { [key: string]: string }
  children?: React.ReactNode | React.ReactNode[]
}

export const SectionContainer = ({
  id,
  icon,
  description,
  backgroundColor,
  iconMargin,
  padding,
  children,
  styles,
}: SectionContainerProps): JSX.Element => {
  return (
    <Grid
      container
      id={id}
      bgcolor={backgroundColor === "light" ? "#F5F5F5" : "#FFFFFF"}
      padding={padding || { md: "56px 24px", sm: "36px 24px", xs: "36px 24px" }}
      style={styles}
    >
      <Grid item width={{ md: "744px", sm: "100%", xs: "100%" }} marginX={"auto"}>
        {(icon || description) && (
          <Grid item md={12} sm={12} xs={12} margin={iconMargin || "0 0 24px"}>
            {icon && <SectionIcon {...icon} />}
            {description && (
              <p dangerouslySetInnerHTML={{ __html: description }} className={commonStyles.description} />
            )}
          </Grid>
        )}
        <div className={commonStyles.displayFlexCenter}>{children}</div>
      </Grid>
    </Grid>
  )
}
