type ServicesList = {
  title: string
  time: string
  price: string
  description?: string
}

export const servicesList: ServicesList[] = [
  {
    title: "OFERTĂ - Tuns! Spălat + Styling GRATUIT!!",
    time: "40 min",
    price: "55 lei",
  },
  {
    title: "Tuns skin fade",
    time: "45 min",
    price: "65 lei",
  },
  {
    title: "Tuns skin fade + aranjat barbă",
    time: "55 min",
    price: "85 lei",
  },
  {
    title: "Vopsit barbă",
    time: "15 min",
    price: "15 lei",
  },
  {
    title: "OFERTĂ - Tuns + Aranjat barbă! Spălat + Styling GRATUIT!!",
    time: "50 min",
    price: "75 lei",
  },
  {
    title: "Spălat + Styling",
    time: "10 min",
    price: "10 lei",
  },
  {
    title: "Spălat + Masaj capilar + Styling",
    time: "15 min",
    price: "15 lei",
  },
  {
    title: "Pensat cu ceară",
    time: "20 min",
    price: "30 lei",
  },
  {
    title: "Epilare facială cu ceară (nas, urechi, contur barbă)",
    time: "30 min",
    price: "30 lei",
  },
  {
    title: "Tratamente faciale (Black mask, mască aurie, mască argintie)",
    time: "45 min",
    price: "50 lei",
    description:
      "Se începe cu un masaj facial cu ajutorul vapozonului pentru deschiderea poriilor, se aplică masca dorită (black mask- pentru a îndepărta punctele negre, masca aurie- pentru a îndepărta punctele albe, masca argintie- pentru a îndepărta punctele negre, ideală pentru pielea sensibilă) , la final, tot prin masaj facial, se aplică o cremă de hidratare.",
  },
  {
    title: "Barbierit cu prosop cald",
    time: "30 min",
    price: "50 lei",
    description:
      "Se folosește un prosop încălzit într-un cuptor special, ulterior se pune pe față cu scopul de a înmuia firele și a deschide porii, în ultimul rând se bărbierește cu lama.",
  },
]
