import React, { useEffect, useState } from "react"
import { Link } from "react-scroll"
import classNames from "classnames"

import { MobileNav } from "../MobileNav/MobileNav"

import { commonStyles } from "../../typestyles/CommonStyles.module"
import { styles, topNav } from "./NavigationBar.module"
import { stylerUrl } from "../../shared/stylerUrl"

const MENU_ITEMS = [
  { title: "Orar", to: "schedule" },
  { title: "Servicii", to: "services" },
  { title: "Prețuri", to: "prices" },
  { title: "Galerie imagini", to: "gallery" },
  { title: "Despre noi", to: "about" },
  { title: "Contact", to: "contact" },
]

const ctaButtonClasses = classNames(commonStyles.btn, commonStyles.btnPrimary)

export const NavigationBar = (): JSX.Element => {
  const [showCtaButton, setShowCtaButton] = useState<boolean>(false)
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation)

    return () => {
      window.removeEventListener("scroll", handleNavigation)
    }
  }, [])

  const handleNavigation = (): void => {
    setShowCtaButton(window.scrollY >= 700)
  }

  const handleMenuOpen = (): void => {
    setIsMobileMenuOpened(!isMobileMenuOpened)
  }

  const topNavClassNames = classNames(styles.topNavWrapper, {
    [commonStyles.vHeight80]: isMobileMenuOpened,
  })

  return (
    <nav className={topNavClassNames}>
      <div className={topNav}>
        {MENU_ITEMS.map((menuItem) => (
          <div key={menuItem.title} className={styles.navItem}>
            <Link
              to={menuItem.to}
              activeClass="active"
              offset={-64}
              spy={true}
              smooth={true}
              className={styles.navLink}
            >
              {menuItem.title}
            </Link>
          </div>
        ))}
        {showCtaButton && (
          <a href={stylerUrl} target={"_blank"} className={ctaButtonClasses}>
            Programare online
          </a>
        )}
      </div>
      <MobileNav isOpened={isMobileMenuOpened} toggleMenu={handleMenuOpen} />
    </nav>
  )
}
